import {AUTH_TOKEN_NAME, LOGIN_TIME_NAME,AUTH_USER_NAME,AUTH_ACCOUNT_ID,AUTH_USER_ID,AUTH_EMAIL_ADDRESS,AUTHORIZED_TOKEN,AUTH_DK_NUMBER,AUTH_USER_TYPE,USER_PICTURE} from '../../helpers/constants';
import {
    USER_LOGIN,
    USER_LIST,
    SELECTED_USER,
    USER_LOGOUT,
    USER_UPDATE, USER_SET_TOKEN, USER_GET_INFO } from '../action/user.action';

export interface LoginUser {
    identity: {
        name: string,
        email: string
    } | any,
    isLoggedIn: boolean,
    VuserId: string | null,
    userName: string | null,
    accountId: string | null,
    userId: string | null,
    emailAddress:string | null,
    dKNumber:string | null,
    userType:string | null,
    ProfilePic:string | null,
    usersList:any,
    selectedUser:any
}

export const DEFAULT_USER_STATE: LoginUser = {
    identity: {},
    isLoggedIn: false,
    VuserId: null,
    userName: null,
    accountId: null,
    userId:null,
    emailAddress:null,
    dKNumber:null,
    userType:null,
    ProfilePic:null,
    usersList:[],
    selectedUser:{}
};

export default function reducer(state = DEFAULT_USER_STATE, action: any) {
    switch (action.type) {
        case USER_LOGIN:
            let VuserId = action.payload.vuserId;
            let UserName = action.payload.userName;
            let accountId = action.payload.accountId;
            let userId = action.payload.userId;
            let emailAddress = action.payload.emailAddress;
            let Token = action.payload.token;
            let dKNumber = action.payload.dkNumber;
            let userType = action.payload.userType;
            let ProfilePic = action.payload.profilePic;
            localStorage.setItem(AUTH_TOKEN_NAME, VuserId);
            localStorage.setItem(AUTH_USER_NAME, UserName);
            localStorage.setItem(AUTH_ACCOUNT_ID, accountId);
            localStorage.setItem(AUTH_USER_ID, userId);
            localStorage.setItem(AUTH_EMAIL_ADDRESS, emailAddress);
            localStorage.setItem(AUTHORIZED_TOKEN, Token);
            localStorage.setItem(AUTH_DK_NUMBER, dKNumber);
            localStorage.setItem(AUTH_USER_TYPE, userType)
            localStorage.setItem(USER_PICTURE, ProfilePic)
            return { ...state, VuserId: VuserId,UserName:UserName,accountId:accountId,userId:userId,emailAddress:emailAddress,Token:Token,dKNumber:dKNumber,userType:userType,ProfilePic:ProfilePic , isLoggedIn: true };

        case USER_GET_INFO:
            return { ...state, identity: action.payload, isLoggedIn: true };

        case USER_LOGOUT:
            localStorage.removeItem(AUTH_TOKEN_NAME);
            localStorage.removeItem(LOGIN_TIME_NAME);
            localStorage.removeItem(AUTH_USER_NAME);
            localStorage.removeItem(AUTH_ACCOUNT_ID);
            localStorage.removeItem(AUTH_USER_ID);
            localStorage.removeItem(AUTH_EMAIL_ADDRESS);
            localStorage.removeItem(AUTHORIZED_TOKEN);
            localStorage.removeItem(AUTH_DK_NUMBER);
            localStorage.removeItem(AUTH_USER_TYPE);
           
            localStorage.removeItem(USER_PICTURE);
            return {identity: {},isLoggedIn: false,VuserId: null,UserName: null,accountId: null,UserId:null,emailAddress:null,Token:null,dKNumber:null,userType:null,ProfilePic:null};

        case USER_UPDATE:
            return { ...state, identity: action.payload };

        case USER_SET_TOKEN:
            let authVuserId = localStorage.getItem(AUTH_TOKEN_NAME);
            let authUserName = localStorage.getItem(AUTH_USER_NAME);
            let authAccountId = localStorage.getItem(AUTH_ACCOUNT_ID);
            let authuserId = localStorage.getItem(AUTH_USER_ID);
            let authemailAddress = localStorage.getItem(AUTH_EMAIL_ADDRESS);
            let authauthTotken = localStorage.getItem(AUTHORIZED_TOKEN);
            let authdKNumber = localStorage.getItem(AUTH_DK_NUMBER);
            let authuserType = localStorage.getItem(AUTH_USER_TYPE);
            let authProfilePic= localStorage.getItem(USER_PICTURE);
            let isLoggedIn = (authauthTotken !== null);
            debugger;
            return { ...state, VuserId: authVuserId,UserName:authUserName,accountId:authAccountId,userId:authuserId, isLoggedIn: isLoggedIn,emailAddress:authemailAddress,Token:authauthTotken,dKNumber:authdKNumber,userType:authuserType ,ProfilePic:authProfilePic };
            case USER_LIST:
                return {
                    ...state,
                    usersList: action.payload
                };
        case SELECTED_USER:
                    return {
                        ...state,
                        selectedUser: action.payload
                    };  
              

        default:
            return state;
    }
    
}