export const LOGIN_TIME_NAME = '__login_time__';
export const AUTH_TOKEN_NAME = '__token__';
export const AUTH_USER_ID = '__userid__';
export const AUTH_ACCOUNT_ID = '__accountid__';
export const AUTH_USER_NAME = '__username__';
export const AUTH_EMAIL_ADDRESS = '__emailAddress__';
export const AUTHORIZED_TOKEN = '__authToken__';
export const AUTH_DK_NUMBER = '__dkNumber__';
export const AUTH_USER_TYPE = '__userType__';
export const USER_PICTURE = '__userPicture__';
export const REPORTING_DATA = '__reportingdata__';
export const SELECTEDPNR='__selected__';
export const DOWNLOADTYPE='__downloadType__';
export const QUEUENUMBER='255';
export const PCCCODE='1B6G';
export const ACCOINTID='393939';
export const APIURL='https://gtitravelarranger20220722193322.azurewebsites.net';// For Sandbox
export const GOOGLE_MAP_API_KEY='AIzaSyA3he25wKRWgPAY7N8t6uh0gLd5v0WunwY';
export  const APITRAVELURL='https://gtltravelapi.azurewebsites.net'; // For Live
//export  const APITRAVELURL='https://testtravelapi.azurewebsites.net'; // For  Sandbox
var CryptoJS = require("crypto-js");
const INCPKEY='520eab5710f04ee7';
//const INCPKEY='8080808080808080';
var KEYVAL = CryptoJS.enc.Utf8.parse(INCPKEY);
var IVVAL = CryptoJS.enc.Utf8.parse(INCPKEY);
export const ENYCRYPTVALUE=(e:any)=>
{
   return CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(e), KEYVAL,  
{
keySize: 128 / 8,   
iv: IVVAL,  
mode: CryptoJS.mode.CBC,  
padding: CryptoJS.pad.Pkcs7 
}).toString(); 
}
export const DECRTIPTVALUE=(cipherText:any)=>  
{  
return CryptoJS.AES.decrypt(cipherText, KEYVAL,  
{
   keySize: 128 / 8,   
   iv: IVVAL,  
   mode: CryptoJS.mode.CBC,  
   padding: CryptoJS.pad.Pkcs7 
}).toString(CryptoJS.enc.Utf8);; 
}

/**
 * Aiirlines Codes List
 */
 export const AIRLINE_CODES = [
   { "name": "Adria Airways", "id": "JP" },
   { "name": "Aegean Airlines", "id": "A3" },
   { "name": "Aer Lingus", "id": "EI" },
   { "name": "P5", "id": "P5" },
   { "name": "Aeroflot", "id": "SU" },
   { "name": "Aerolineas Argentinas", "id": "AR" },
   { "name": "Aerolineas Galapagos S.A. Aerogal", "id": "2K" },
   { "name": "Aeromexico", "id": "AM" },
   { "name": "Africa World Airlines", "id": "AW" },
   { "name": "Aigle Azur", "id": "ZI" },
   { "name": "AH", "id": "AH" },
   { "name": "Air Arabia", "id": "G9" },
   { "name": "Air Astana", "id": "KC" },
   { "name": "Air Austral", "id": "UU" },
   { "name": "Air Baltic", "id": "BT" },
   { "name": "Air Berlin", "id": "AB" },
   { "name": "Air Botswana", "id": "BP" },
   { "name": "Air Burkina", "id": "2J" },
   { "name": "Air Cairo", "id": "SM" },
   { "name": "Air Caledonie", "id": "TY" },
   { "name": "Air Canada", "id": "AC" },
   { "name": "Air Caraibes", "id": "TX" },
   { "name": "Air China", "id": "CA" },
   { "name": "Air Corsica", "id": "XK" },
   { "name": "Air Europa", "id": "UX" },
   { "name": "Air France", "id": "AF" },
   { "name": "Air India", "id": "AI" },
   { "name": "Air Koryo", "id": "JS" },
   { "name": "Air Macau", "id": "NX" },
   { "name": "Air Madagascar", "id": "MD" },
   { "name": "Air Malta", "id": "KM" },
   { "name": "Air Mauritius", "id": "MK" },
   { "name": "Air Moldova", "id": "9U" },
   { "name": "Air Namibia", "id": "SW" },
   { "name": "Air New Zealand", "id": "NZ" },
   { "name": "Air Niugini", "id": "PX" },
   { "name": "Air Nostrum", "id": "YW" }, { "name": "Air Peace", "id": "P4" },
   { "name": "Air SERBIA a.d. Beograd", "id": "JU" }, { "name": "Air Seychelles", "id": "HM" },
   { "name": "Air Tahiti", "id": "VT" }, { "name": "Air Tahiti Nui", "id": "TN" },
   { "name": "Air Transat", "id": "TS" }, { "name": "Air Vanuatu", "id": "NF" },
   { "name": "AirBridgeCargo Airlines", "id": "RU" }, { "name": "Aircalin", "id": "SB" },
   { "name": "Airlink", "id": "4Z" }, { "name": "Alaska Airlines", "id": "AS" },
   { "name": "Alitalia", "id": "AZ" }, { "name": "All Nippon Airways", "id": "NH" },
   { "name": "Allied Air", "id": "4W" }, { "name": "AlMasria Universal Airlines", "id": "UJ" },
   { "name": "American Airlines", "id": "AA" }, { "name": "Arik Air", "id": "W3" },
   { "name": "Arkia Israeli Airlines", "id": "IZ" }, { "name": "Asiana", "id": "OZ" },
   { "name": "Atlas Air", "id": "5Y" }, { "name": "AtlasGlobal", "id": "KK" },
   { "name": "Austral", "id": "AU" }, { "name": "Austrian", "id": "OS" },
   { "name": "Avianca", "id": "AV" }, { "name": "Avianca Brasil", "id": "O6" },
   { "name": "Azerbaijan Airlines", "id": "J2" },
   { "name": "Azul Brazilian Airlines", "id": "AD" },
   { "name": "Bahamasair", "id": "UP" },
   { "name": "Bangkok Air", "id": "PG" },
   { "name": "Belavia - Belarusian Airlines", "id": "B2" },
   { "name": "BH AIR", "id": "8H" },
   { "name": "Biman", "id": "BG" },
   { "name": "Binter Canarias", "id": "NT" },
   { "name": "Blue Air", "id": "0B" },
   { "name": "Blue Panorama", "id": "BV" },
   { "name": "bmi Regional", "id": "BM" },
   { "name": "OB", "id": "OB" },
   { "name": "Braathens Regional Aviation AB", "id": "TF" },
   { "name": "British Airways", "id": "BA" },
   { "name": "Brussels Airlines", "id": "SN" },
   { "name": "Bulgaria air", "id": "FB" },
   { "name": "C.A.L. Cargo Airlines", "id": "5C" },
   { "name": "Camair-Co", "id": "QC" },
   { "name": "Cambodia Angkor Air", "id": "K6" },
   { "name": "Capital Airlines", "id": "JD" },
   { "name": "Cargojet Airways", "id": "W8" },
   { "name": "Cargolux S.A.", "id": "CV" },
   { "name": "Caribbean Airlines", "id": "BW" },
   { "name": "Carpatair", "id": "V3" },
   { "name": "Cathay Dragon", "id": "KA" },
   { "name": "Cathay Pacific", "id": "CX" },
   { "name": "Cayman Airways", "id": "KX" },
   { "name": "Cemair", "id": "5Z" },
   { "name": "China Airlines", "id": "CI" },
   { "name": "China Cargo Airlines", "id": "CK" },
   { "name": "China Eastern", "id": "MU" },
   { "name": "China Express Airlines", "id": "G5" },
   { "name": "China Postal Airlines", "id": "CF" },
   { "name": "China Southern Airlines", "id": "CZ" },
   { "name": "CityJet", "id": "WX" }, { "name": "Comair", "id": "MN" },
   { "name": "Condor", "id": "DE" }, { "name": "COPA Airlines", "id": "CM" },
   { "name": "Corendon Airlines", "id": "XC" },
   { "name": "Corsair International", "id": "SS" },
   { "name": "Croatia Airlines", "id": "OU" },
   { "name": "Cubana", "id": "CU" },
   { "name": "Czech Airlines", "id": "OK" },
   { "name": "Dana Air", "id": "9J" },
   { "name": "Delta Air Lines", "id": "DL" },
   { "name": "DHL Air", "id": "D0" },
   { "name": "DHL Aviation EEMEA B.S.C.(c)", "id": "ES*" },
   { "name": "Eastar Jet", "id": "ZE" },
   { "name": "Egyptair", "id": "MS" },
   { "name": "EL AL", "id": "LY" },
   { "name": "Emirates", "id": "EK" },
   { "name": "Ethiopian Airlines", "id": "ET" },
   { "name": "Etihad Airways", "id": "EY" },
   { "name": "Euroatlantic Airways", "id": "YU" },
   { "name": "European Air Transport", "id": "QY" },
   { "name": "Eurowings", "id": "EW" },
   { "name": "EVA Air", "id": "BR" },
   { "name": "Evelop Airlines", "id": "E9" },
   { "name": "Federal Express", "id": "FX" },
   { "name": "Fiji Airways", "id": "FJ" },
   { "name": "Finnair", "id": "AY" },
   { "name": "flybe", "id": "BE" },
   { "name": "flydubai", "id": "FZ" },
   { "name": "FlyEgypt", "id": "FT" },
   { "name": "Freebird Airlines", "id": "FH" },
   { "name": "Garuda", "id": "GA" },
   { "name": "Georgian Airways", "id": "A9" },
   { "name": "Germania", "id": "ST" }, { "name": "G3", "id": "G3" },
   { "name": "Guangxi Beibu Gulf Airlines", "id": "GX" },
   { "name": "Gulf Air", "id": "GF" }, { "name": "Hahn Air", "id": "HR*" },
   { "name": "Hainan Airlines", "id": "HU" }, { "name": "Hawaiian Airlines", "id": "HA" },
   { "name": "Hi Fly", "id": "5K" }, { "name": "Hong Kong Airlines", "id": "HX" },
   { "name": "Hong Kong Express Airways", "id": "UO" }, { "name": "IBERIA", "id": "IB" }, { "name": "Icelandair", "id": "FI" },
   { "name": "InselAir", "id": "7i" }, { "name": "Interjet", "id": "4O" }, { "name": "Iran Air", "id": "IR" },
   { "name": "Iran Airtour Airline", "id": "B9" }, { "name": "Iran Aseman Airlines", "id": "EP" }, { "name": "Israir", "id": "6H" },
   { "name": "Japan Airlines", "id": "JL" }, { "name": "Jazeera Airways", "id": "J9" }, { "name": "Jet Airways", "id": "9W" },
   { "name": "Jet Lite (India)", "id": "S2" }, { "name": "JetBlue", "id": "B6" }, { "name": "Jordan Aviation", "id": "R5" },
   { "name": "JSC Nordavia-RA", "id": "5N" }, { "name": "Juneyao Airlines", "id": "HO" }, { "name": "Kenya Airways", "id": "KQ" },
   { "name": "Kish Air", "id": "Y9" }, { "name": "KLM", "id": "KL" }, { "name": "Korean Air", "id": "KE" }, { "name": "Kuwait Airways", "id": "KU" },
   { "name": "LACSA", "id": "LR" }, { "name": "LAM", "id": "TM" }, { "name": "Lao Airlines", "id": "QV" }, { "name": "LATAM Airlines Argentina", "id": "4M" },
   { "name": "LATAM Airlines Brasil", "id": "JJ" }, { "name": "LATAM Airlines Colombia", "id": "4C" }, { "name": "LATAM Airlines Ecuador", "id": "XL" },
   { "name": "LATAM Airlines Group", "id": "LA" }, { "name": "LATAM Airlines Paraguay", "id": "PZ" }, { "name": "LATAM Airlines Peru", "id": "LP" },
   { "name": "LATAM Cargo Brasil", "id": "M3" }, { "name": "LATAM Cargo Chile", "id": "UC" }, { "name": "LATAM Cargo Mexico", "id": "M7" },
   { "name": "LIAT Airlines", "id": "LI" }, { "name": "LLC ", "id": "N4" }, { "name": "Loong Air", "id": "GJ" }, { "name": "LOT Polish Airlines", "id": "LO" },
   { "name": "Lucky Air", "id": "8L" }, { "name": "Lufthansa", "id": "LH" }, { "name": "Lufthansa Cargo", "id": "LH" },
   { "name": "Lufthansa CityLine", "id": "CL" }, { "name": "Luxair", "id": "LG" }, { "name": "Mahan Air", "id": "W5" },
   { "name": "Malaysia Airlines", "id": "MH" }, { "name": "Malindo Air", "id": "OD" }, { "name": "Mandarin Airlines", "id": "AE" },
   { "name": "Martinair Cargo", "id": "MP" }, { "name": "Mauritania Airlines International", "id": "L6" }, { "name": "MEA", "id": "ME" },
   { "name": "Meridiana fly", "id": "IG" }, { "name": "MIAT", "id": "OM" }, { "name": "Mistral Air", "id": "M4" }, { "name": "MNG Airlines", "id": "MB" },
   { "name": "Montenegro Airlines", "id": "YM" }, { "name": "Myanmar Airways International", "id": "8M" }, { "name": "Neos", "id": "NO" },
   { "name": "Nesma Airlines", "id": "NE" }, { "name": "Nextjet", "id": "2N" }, { "name": "NIKI", "id": "HG" }, { "name": "Nile Air", "id": "NP" },
   { "name": "Nippon Cargo Airlines (NCA)", "id": "KZ" }, { "name": "Nouvelair", "id": "BJ" }, { "name": "Okay Airways", "id": "BK" },
   { "name": "Olympic Air", "id": "OA" }, { "name": "Oman Air", "id": "WY" }, { "name": "Onur Air", "id": "8Q" }, { "name": "Overland Airways", "id": "OF" },
   { "name": "Pegas Fly", "id": "IK" }, { "name": "Pegasus Airlines", "id": "PC" }, { "name": "", "id": "NI" }, { "name": "Philippine Airlines", "id": "PR" },
   { "name": "PIA", "id": "PK" }, { "name": "Precision Air", "id": "PW" }, { "name": "PrivatAir", "id": "PV" }, { "name": "Qantas", "id": "QF" },
   { "name": "Qatar Airways", "id": "QR" }, { "name": "Rossiya Airlines", "id": "FV" }, { "name": "Royal Air Maroc", "id": "AT" },
   { "name": "Royal Brunei", "id": "BI" }, { "name": "Royal Jordanian", "id": "RJ" }, { "name": "RwandAir", "id": "WB" },
   { "name": "S7 Airlines", "id": "S7" }, { "name": "SAA", "id": "SA" }, { "name": "Safair", "id": "FA*" }, { "name": "Safi Airways", "id": "4Q" },
   { "name": "Santa Barbara", "id": "S3" }, { "name": "SAS", "id": "SK" }, { "name": "", "id": "SP" }, { "name": "SATA Internacional", "id": "S4" },
   { "name": "Saudi Arabian Airlines", "id": "SV" }, { "name": "SF Airlines", "id": "O3" }, { "name": "Shandong Airlines", "id": "SC" },
   { "name": "Shanghai Airlines", "id": "FM" }, { "name": "Shenzhen Airlines", "id": "ZH" }, { "name": "SIA", "id": "SQ" },
   { "name": "SIA Cargo", "id": "SQ" }, { "name": "Sichuan Airlines", "id": "3U" }, { "name": "Silk Way West Airlines", "id": "7L" },
   { "name": "Silkair", "id": "MI" }, { "name": "SKY Airline", "id": "H2" }, { "name": "Somon Air", "id": "SZ" },
   { "name": "South African Express Airways", "id": "XZ" }, { "name": "SriLankan", "id": "UL" }, { "name": "SunExpress", "id": "XQ*" },
   { "name": "Suparna Airlines", "id": "Y8" }, { "name": "Surinam Airways", "id": "PY" }, { "name": "SWISS", "id": "LX" },
   { "name": "Syrianair", "id": "RB" }, { "name": "TAAG - Angola Airlines", "id": "DT" }, { "name": "TACA", "id": "TA" },
   { "name": "TACA Peru", "id": "T0" }, { "name": "TACV Cabo Verde Airlines", "id": "VR" }, { "name": "EQ", "id": "EQ" }, { "name": "TAP Portugal", "id": "TP" },
   { "name": "TAROM", "id": "RO" }, { "name": "Tassili Airlines", "id": "SF" }, { "name": "Thai Airways International", "id": "TG" },
   { "name": "Thai Lion Air", "id": "SL" }, { "name": "Turkish Airlines", "id": "TK" }, { "name": "Tianjin Airlines", "id": "GS" },
   { "name": "TUIfly", "id": "X3*" }, { "name": "Tunisair", "id": "TU" }, { "name": "T'way Air", "id": "TW" },
   { "name": "Ukraine International Airlines", "id": "PS" }, { "name": "United Airlines", "id": "UA" }, { "name": "UPS Airlines", "id": "5X" },
   { "name": "Ural Airlines", "id": "U6" }, { "name": "UTair", "id": "UT" }, { "name": "Uzbekistan Airways", "id": "HY" },
   { "name": "Vietjet", "id": "VJ" }, { "name": "Vietnam Airlines", "id": "VN" }, { "name": "VIM Airlines", "id": "NN" },
   { "name": "Virgin Atlantic", "id": "VS" }, { "name": "Virgin Australia", "id": "VA" }, { "name": "Volaris", "id": "Y4*" },
   { "name": "Vueling", "id": "VY" }, { "name": "Wamos Air", "id": "EB" }, { "name": "WestJet", "id": "WS" }, { "name": "White coloured by you", "id": "WI" },
   { "name": "Wideroe", "id": "WF" }, { "name": "Xiamen Airlines", "id": "MF" },
   { "name": "Spirit", "id": "NK" }, { "name": "Frontier", "id": "F9" }, { "name": "Southwest", "id": "WN" }
];