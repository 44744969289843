
//import { useNavigate  } from "react-router-dom";
import './css/style.css';
import Navigator from "./components/Navigator";
// Initialize Firebase
const App = () => {
  //const history = useNavigate();
  return <Navigator/>;
};

export default App;
