import * as actionTypes from '../action/pnr.action';
export interface firbaseList {
    pnrList:any,
    pnrListHistory:any,
    selectedPnr:any
}

export const DEFAULT_PNR_LIST_STATE: firbaseList = {
    pnrList: [],
    pnrListHistory: [],
    selectedPnr:''
};

const reducer = (state=DEFAULT_PNR_LIST_STATE, action) => {
    switch (action.type) {
        case actionTypes.PNR_LIST:
            return {
                ...state,
                pnrList: action.payload
            };
    case actionTypes.PNR_LIST_HISTORY:
                return {
                    ...state,
                    pnrListHistory: action.payload
                };       
    case actionTypes.SELECTED_PNR:
                return {
                    ...state,
                    selectedPnr: action.payload
                };  
            default:
                return state;
       
    };   

}
export default reducer;